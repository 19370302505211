body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "PingFang SC",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modalOpen {
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
}
header {
  position: fixed;
  z-index: 3;
  height: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header .headerWrapper {
  width: 100vw;
  padding: 0 23vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
header .headerWrapper .logoBox {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
header .headerWrapper .logoBox img {
  width: 14.2vw;
  height: 2.6vw;
}
header .headerWrapper .menuBox {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
header .phoneHeader {
  height: 40px;
  padding: 0 21px;
}
header .phoneHeader .logoBox {
  font-size: 4vw;
}
header .phoneHeader .logoBox img {
  width: 105px;
  height: 23px;
  margin-right: 2vw;
}
header .phoneHeader .nav-phone-menu {
  fill: #fff;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
header .button .ant-btn {
  padding: 0 1vw;
  border-radius: 1vw;
  font-size: 1.3vw;
  height: 2.6vw;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: transparent;
  box-shadow: 0 0 0;
}
header .button .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #fff;
}
header .splitters {
  margin: 0 1.1vw;
  height: 1.2vw;
  width: 1px;
  border-left: 1px solid #fff;
}
header .active-btn {
  float: right;
  height: 100%;
}
header .active-btn .ant-btn {
  display: block;
  padding: 0 1vw;
  border-radius: 1vw;
  font-size: 1.3vw;
  height: 2.6vw;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: #2253A3;
  box-shadow: 0 0 0;
}
header .active-btn .ant-btn:hover {
  background-color: rgba(245, 191, 76, 0.7);
  color: #fff;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white {
  background-color: #FFF;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white .headerWrapper .logoBox {
  color: #2253A3;
}
header.btn-white .headerWrapper .splitters {
  border-left: 1px solid #2253A3;
}
header.btn-white .button .ant-btn {
  color: #2253A3;
}
header.btn-white .button .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #2253A3;
}
header.btn-white .active-btn .ant-btn {
  background-color: #2253A3;
}
header.btn-white .active-btn .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #2253A3;
}
header.btn-white .phoneHeader .nav-phone-menu {
  fill: #2253A3;
}
.phoneTop {
  height: auto;
}
.popover-menu {
  width: 40vw;
  left: 27vw;
}
.popover-menu button.lang {
  margin: 16px auto;
  float: none;
}
.popover-menu div.version {
  margin: 32px auto 16px;
  float: none;
}
.popover-menu .ant-popover-inner {
  overflow: hidden;
}
.popover-menu .ant-popover-inner-content {
  padding: 0;
}
.popover-menu .ant-popover-content ul li {
  padding: 0;
}
.popover-menu .ant-popover-content ul li span {
  color: #2253A3;
}
.popover-menu .ant-popover-content ul li:hover {
  background: #2bc0a14d;
}
.popover-menu .ant-popover-content ul li::selection {
  background: #2253A3;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item-selected {
  background: #2bc0a14d;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item::after {
  border-right: 4px solid #2253A3;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
#reat-content {
  font-family: Roboto, PingFang SC, Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.page-wrapper {
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.phonePage .solution-wrapper {
  height: auto;
}
.phonePage .solution-wrapper .title {
  font-size: 16px;
  line-height: 28px;
}
.phonePage .solution-wrapper .allSolution {
  width: auto;
  padding: 0 35px;
}
.phonePage .solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo img {
  width: 50px;
  height: 50px;
}
.phonePage .solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo .solutonDetail .oneRow {
  font-size: 14px;
  line-height: 20px;
}
.phonePage .solution-wrapper .allSolution .oneSoluton .soluInfo .moneyInfo .oneRow {
  font-size: 14px;
  line-height: 20px;
}
.phonePage .solution-wrapper .allSolution .oneSoluton .solutonTitle {
  display: none;
}
.phonePage .solution-wrapper .tip {
  width: auto;
  text-align: center;
  padding-bottom: 14px;
  font-size: 14px;
  line-height: 20px;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.page h2,
.page h4,
.page h5,
.page span,
.page p,
.page .feature,
.page .card-wrapper,
.page .main-info,
.page .detail {
  will-change: transform;
}
.page .main-info {
  line-height: 36px;
}
.banner {
  width: 100%;
  margin: auto;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.banner-wrapper {
  background: #f8fafe;
  min-height: 320px;
}
.banner > div {
  display: inline-block;
}
.banner-bg-img {
  width: 100%;
  height: 100vh;
}
.banner-bg-word-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-top: 196px;
  overflow: hidden;
}
.banner-word-main {
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.banner-word-left {
  -webkit-flex: 3 1;
          flex: 3 1;
}
.banner-word-left-title {
  padding: 0;
  margin: 0;
  width: 400px;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 46px;
  color: #FFFFFF;
}
.banner-word-left-subtitle {
  padding: 0;
  margin: 39px 0 16px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-left-desc {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-download {
  margin-top: 53px;
  width: 195px;
  height: 44px;
  background: #F5BF4C;
  border-radius: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download:hover {
  background: rgba(245, 191, 76, 0.7);
  color: #fff;
}
.banner-word-img-wrapper {
  -webkit-flex: 4 1;
          flex: 4 1;
  padding: 88px;
}
.banner-word-img-wrapper img {
  width: 417px;
  height: 336px;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.banner-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 0 23vw;
  height: 56.3vw;
  background: #22186E;
  background: url(../../static/media/bannerBg.cf8a7614.png) 100% 100% / auto 100% no-repeat;
  padding-top: 15.6vw;
}
.banner-wrapper .banner-constent {
  margin: 0 auto;
}
.banner-wrapper .banner-constent .banner-desc {
  width: 22vw;
  font-size: 2.1vw;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 3.1vw;
  color: #FFFFFF;
}
.phoneBanner {
  padding: 0 35px;
  padding-top: 150px;
  width: 100%;
  height: 500px;
}
.phoneBanner .banner-constent .banner-desc {
  width: auto;
  font-size: 39px;
  line-height: 43px;
}
.feature-wrapper {
  background: #fff;
  padding-top: 10.4vw;
  padding-bottom: 4.2vw;
}
.feature-wrapper .item-feature {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 3.3vw;
}
.feature-wrapper .item-feature .leftItem {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.feature-wrapper .item-feature .leftItem .featureDesc {
  width: 27vw;
  text-align: right;
}
.feature-wrapper .item-feature .marginBox {
  width: 5.2vw;
}
.feature-wrapper .item-feature .rightItem {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.feature-wrapper .item-feature .rightItem .featureDesc {
  width: 28.3vw;
  text-align: left;
}
.feature-wrapper .item-feature .cardImg {
  width: 26.1vw;
  height: 17.4vw;
}
.feature-wrapper .item-feature .cardTitle {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 3vw;
  color: #4D4D4D;
}
.feature-wrapper .item-feature .cardDetail {
  margin-top: 0.5vw;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 1.7vw;
  color: #4D4D4D;
}
.phoneFeature {
  position: relative;
  min-height: 500px;
  height: auto;
}
.phoneFeature .item-feature {
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 50px;
}
.phoneFeature .item-feature .marginBox {
  display: none;
}
.phoneFeature .item-feature .leftItem {
  position: relative;
  display: block;
  z-index: 2;
}
.phoneFeature .item-feature .leftItem .featureDesc {
  position: relative;
  z-index: 2;
  width: 280px;
  padding-left: 35px;
  text-align: left;
}
.phoneFeature .item-feature .leftItem img {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 1;
  width: 150px;
  max-height: 300px;
  height: auto;
}
.phoneFeature .item-feature .leftItem .cardTitle {
  font-size: 16px;
  line-height: 28px;
}
.phoneFeature .item-feature .leftItem .cardDetail {
  font-size: 14px;
  line-height: 20px;
}
.phoneFeature .item-feature .rightItem {
  position: relative;
  display: block;
  z-index: 2;
}
.phoneFeature .item-feature .rightItem img {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 150px;
  z-index: 1;
  height: auto;
}
.phoneFeature .item-feature .rightItem .featureDesc {
  position: relative;
  z-index: 2;
  width: 280px;
  padding-right: 35px;
  text-align: right;
}
.phoneFeature .item-feature .rightItem .featureDesc .cardTitle {
  font-size: 16px;
  line-height: 28px;
}
.phoneFeature .item-feature .rightItem .featureDesc .cardDetail {
  font-size: 14px;
  line-height: 20px;
}
.phoneFeature .item-feature:nth-child(1) .leftItem img {
  margin-top: -60px;
}
.phoneFeature .item-feature:nth-child(3) .leftItem img {
  margin-top: -50px;
}
.solution-wrapper {
  z-index: 1;
  width: 100%;
  height: 80vw;
  background: #fff;
  background: url(../../static/media/solutionBg.4becc9e2.png) 100% 100% / auto 100% no-repeat;
  padding-top: 11.6vw;
}
.solution-wrapper .title {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3vw;
  color: #4D4D4D;
  text-align: center;
}
.solution-wrapper .allSolution {
  margin: 0 auto;
  width: 62.9vw;
}
.solution-wrapper .allSolution .oneSoluton {
  margin-top: 4vw;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo img {
  width: 3.9vw;
  height: 3.9vw;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo .solutonDetail {
  margin-left: 20px;
  text-align: left;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo .solutonDetail .oneRow {
  text-align: left;
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 1.3vw;
  color: #4D4D4D;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .leftInfo .solutonDetail .moreRow {
  margin-top: 7px;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .moneyInfo {
  text-align: right;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .moneyInfo .oneRow {
  text-align: right;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 1.3vw;
  color: #D35D4E;
}
.solution-wrapper .allSolution .oneSoluton .soluInfo .moneyInfo .moreRow {
  margin-top: 5px;
}
.solution-wrapper .allSolution .oneSoluton .solutonTitle {
  margin-top: 1vw;
  width: 3.9vw;
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 1.3vw;
  color: #4D4D4D;
  text-align: center;
}
.solution-wrapper .tip {
  margin: 0 auto;
  margin-top: 2vw;
  width: 62.9vw;
  text-align: left;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.3vw;
  color: #9D9D9D;
}
.solution-wrapper .buy {
  margin: 0 auto;
  cursor: pointer;
  margin-top: 1.6vw;
  width: 20vw;
  height: 2.1vw;
  line-height: 2.1vw;
  background: #24368c;
  text-align: center;
  opacity: 1;
  border-radius: 0.8vw;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: 1px;
}
.contact-wrapper {
  margin-top: -27.6vw;
  position: relative;
}
.contact-wrapper .title {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3vw;
  color: #4D4D4D;
  text-align: center;
}
.contact-wrapper .contactDetail {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  width: 62.5vw;
  height: 20.8vw;
  background: #FFFFFF;
  border-radius: 1.1vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.contact-wrapper .contactDetail .logoImg {
  width: 9.3vw;
  height: 7.4vw;
  margin: 0 3.1vw 0 2.6vw;
}
.contact-wrapper .contactDetail .detailInfo {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0 3.1vw;
  border-left: 1px solid #ccc;
}
.contact-wrapper .contactDetail .detailInfo .oneRow {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .commonIcon {
  width: 2.6vw;
  height: 2.6vw;
  margin-right: 1.6vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .emailIcon {
  width: 2.1vw;
  height: 2.1vw;
  margin-right: 1.9vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .telIcon {
  width: 2.3vw;
  height: 2.3vw;
  margin-right: 1.7vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .detail {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 1.7vw;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .detail .marTop {
  margin-top: 0.5vw;
}
.contact-wrapper .contactDetail .detailInfo .marginB {
  margin-bottom: 1.3vw;
  -webkit-align-items: center;
          align-items: center;
}
.contact-wrapper .footerBg {
  position: relative;
  z-index: 0;
  width: 100%;
  background: #2253A3;
  height: 7.3vw;
  margin-top: -2.1vw;
}
.phoneContact {
  margin-top: 0;
  padding-top: 14px;
}
.phoneContact .title {
  font-size: 16px ;
  padding-bottom: 21px;
}
.phoneContact .contactDetail {
  height: auto;
  width: 100%;
  padding: 0 65px;
}
.phoneContact .contactDetail .logoImg {
  width: 50px;
  height: 38px;
}
.phoneContact .contactDetail .detailInfo .oneRow .detail {
  font-size: 14px ;
  line-height: 20px;
}
.phoneContact .footerBg {
  display: none;
}
.login {
  position: fixed;
  top: 0;
  z-index: 3;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.login .content {
  position: relative;
  width: 27vw;
  border-radius: 8px;
  background: #ffffff;
}
.login .content .top {
  position: relative;
  border-radius: 8px 8px 0px 0px;
  height: 7.9vw;
  background: #2253a3;
  opacity: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.login .content .top img {
  width: 6vw;
  height: 4.7vw;
}
.login .content .top .close {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: -2.3vw;
  width: 2.3vw;
  height: 2.3vw;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 4px 4px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.login .content .top .close img {
  width: 1.3vw;
  height: 1.3vw;
}
.login .content .info {
  position: relative;
  padding: 2vw;
  padding-top: 1.2vw;
}
.login .content .info .title {
  position: relative;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 0.9vw;
  color: #2253A3;
  letter-spacing: 1px;
}
.login .content .info .item {
  position: relative;
  height: 2vw;
  margin-top: 1vw;
  margin-bottom: 0.8vw;
  background: #f9f9fd;
  border: 0;
  line-height: 2vw;
}
.login .content .info .item .inputItem {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 1.2vw;
  background: #f9f9fd;
  border: 0;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #4D4D4D;
  letter-spacing: 1px;
  cursor: pointer;
}
.login .content .info .item .inputItem:focus {
  outline: 1px solid #2253A3 !important;
}
.login .content .info .item input > :focus {
  border: 1px solid #2253A3 !important;
}
.login .content .info .item .send {
  position: absolute;
  right: 12px;
  top: 0;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2253A3;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.login .content .info .action {
  margin: 0 2vw;
  cursor: pointer;
  height: 2vw;
  background: #2253a3;
  opacity: 1;
  border-radius: 8px;
  margin-top: 2vw;
  font-size: 0.9px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 2vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  opacity: 0.5;
  text-align: center;
}
.login .content .info .do {
  opacity: 1;
}
.buyModal {
  position: fixed;
  top: 0;
  z-index: 100;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.buyModal .content {
  position: relative;
  width: 52vw;
  padding: 0 2.6vw;
  border-radius: 8px;
  background: #ffffff;
}
.buyModal .content .close {
  position: absolute;
  top: 15%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: -3vw;
  width: 3vw;
  height: 3vw;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 5px 5px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.buyModal .content .close img {
  width: 1.7vw;
  height: 1.7vw;
}
.buyModal .content .info {
  margin: 0 auto;
  width: 100%;
  z-index: 101;
}
.buyModal .content .info .oneSoluton .soluInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 1px dashed #E5E5E5;
  padding: 1vw 0;
}
.buyModal .content .info .oneSoluton .soluInfo .leftInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.buyModal .content .info .oneSoluton .soluInfo .leftInfo img {
  width: 5.2vw;
  height: 5.2vw;
}
.buyModal .content .info .oneSoluton .soluInfo .leftInfo .solutonDetail {
  margin-left: 20px;
  text-align: left;
}
.buyModal .content .info .oneSoluton .soluInfo .leftInfo .solutonDetail .solutonTitle {
  font-size: 1vw;
  font-family: PingFang SC;
  line-height: 1.3vw;
  font-weight: bold;
  color: #4D4D4D;
}
.buyModal .content .info .oneSoluton .soluInfo .leftInfo .solutonDetail .oneRow {
  padding-top: 0.5vw;
  text-align: left;
  font-size: 0.8vw;
  font-family: PingFang SC;
  color: #4D4D4D;
}
.buyModal .content .info .oneSoluton .soluInfo .moneyInfo {
  text-align: right;
}
.buyModal .content .info .oneSoluton .soluInfo .moneyInfo .oneRow {
  text-align: right;
  font-size: 1.3vw;
  font-family: PingFang SC;
  line-height: 1.3vw;
  color: #D35D4E;
}
.buyModal .content .info .oneSoluton .soluInfo .moneyInfo .moreRow {
  margin-top: 5px;
}
.buyModal .content .info .oneSoluton .soluInfo:last-child {
  border-bottom: 1px solid #E5E5E5;
}
.buyModal .content .total {
  display: -webkit-flex;
  display: flex;
  padding-top: 1.6vw;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.6vw;
  color: #4D4D4D;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-weight: bold;
}
.buyModal .content .total .money {
  color: #D35D4E;
}
.buyModal .content .payImgs {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  padding-top: 2.6vw;
}
.buyModal .content .payImgs .item {
  padding: 0 1vw;
  height: 3.1vw;
  background: rgba(255, 255, 255, 0.39);
  border: 1px solid #E6E6E6;
  opacity: 1;
  border-radius: 0.5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 2.6vw;
  margin-bottom: 1.6vw;
}
.buyModal .content .payImgs .item .payImg {
  height: 2.1vw;
  margin-left: 1.4vw;
}
.buyModal .content .payImgs .item .option {
  width: 1.3vw;
  height: 1.3vw;
  background: #ffffff;
}
.buyModal .content .payImgs .item .select {
  border-radius: 50%;
}
.buyModal .content .pay {
  width: 21vw;
  height: 2.6vw;
  margin: 1.6vw auto;
  text-align: center;
  background: #f8ba56;
  opacity: 1;
  border-radius: 1vw;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.6vw;
  color: #FFFFFF;
  letter-spacing: 1px;
  cursor: pointer;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer-wrapper {
  background: #24368C;
  height: 3.4vw;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3.4vw;
  color: #FFFFFF;
  text-align: center;
}

